import md5 from 'md5'
import axios from 'axios';

const domain = "bachtrack.com"
const username = "mahlerfoundation";
const password = "weo49rb1P0QEGZ3KE65zjqVgTxRUD";
const language = "en_GB";
const action = "find-events"
const params = ""
const timestamp = Math.round(+new Date()/1000)
const token = md5(`${username}-${password}-${timestamp}`)

function getEvents(){
    const url = `https://${domain}/${language}/json/api-server/call/${username}/${timestamp}/${token}/${action}/`
    return new Promise((resolve, reject) => {
        axios.get(url)
        .then(res => {
            console.log(res.data.data)
            resolve(res.data.data.events)
        })
        .catch(err => {
            console.log(err)
            reject(err)
        })
    })
}

export{
    getEvents
}

