import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';
import { Column } from 'primereact/column';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { getEvents } from './domain/adapters/fetchBachtrackAPI'
import { Toast } from 'primereact/toast';


import FakeAPI from './fake-api.json'

import '/node_modules/primeflex/primeflex.css'

import './App.css'

function App() {
  let eventsStorage = [];

  const toastError = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);

  useEffect(function () {
    getEvents()
      .then(events => {
        eventsStorage = events
        setData(events)
      })
      .catch(err => showError())
    // setData(FakeAPI.events)

  }, [])

  console.log(FakeAPI)

  const handleSearch = (e) => {
    e.preventDefault()
    // Implement your search logic here and update the 'data' state with the search results
    // For now, we'll just filter data that contains the search text
    const filteredData = eventsStorage.filter(item =>
      // const filteredData = data.filter(item =>
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.cityname.toLowerCase().includes(searchText.toLowerCase()) ||
      item.countryname.toLowerCase().includes(searchText.toLowerCase()) ||
      item.venuename.toLowerCase().includes(searchText.toLowerCase()) ||
      item.performers.some(p =>
        p.name.toLowerCase().includes(searchText.toLowerCase()) ||
        p.instrumentname.toLowerCase().includes(searchText.toLowerCase())
      ) ||
      item.programme.some(p =>
        p.composername.toLowerCase().includes(searchText.toLowerCase()) ||
        p.workname.toLowerCase().includes(searchText.toLowerCase()))
    );
    setData(filteredData);
  };

  const imageBodyTemplate = (item) => {
    if (item.thumbnail.url.includes("https"))
      return <img src={`${item.thumbnail.url}`} alt={item.thumbnail.url} className="w-6rem shadow-2 border-round concert-image" />;
    else
      return <img src={`https://bachtrack.com${item.thumbnail.url}`} alt={item.thumbnail.alt} className="w-6rem shadow-2 border-round concert-image" />;
  };

  const itemHTMLDesription = (item) => {
    return <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
  }

  const showError = () => toastError.current.show({severity:'error', summary: "Error Bachtrack", detail:"Error connecting to Server", life: 3000})

  const rowContent = (item) => {
    return (
      <>
        <div className='grid' style={{ "width": "100%" }}>
          <div className='col-2'>{item.occurrences.length > 0 ? item.occurrences.map(oc => <div>{oc.date}&nbsp; at {oc.starttime}</div>) : null}</div>
          <div className='col-7 sm:col-5'>
            <div className='grid'>
              <div className='col-12'>{item.venuename}, {item.cityname}</div>
              <div className='col-12'>{imageBodyTemplate(item)}</div>
            </div>
          </div>
          <div className='col-10 sm:col-5'>
            <div className='grid'>
              <div className='col-12'>{item.programme.length > 0 ? item.programme.map(pro => <div>{pro.composershortname}, {pro.workname}</div>) : null}</div>
              <div className='space'></div>
              <div className='col-12'>{item.performers.length > 0 ? item.performers.map(per => <div>{per.name}, {per.instumentname}</div>) : null}</div>
            </div>
          </div>
          <div className='col-12'>
            <DetailsContent item={item} />
          </div>
        </div>
      </>
    )
  }

  const DetailsContent = ({ item }) => {
    return (
      <Accordion activeIndex={1}>
        <AccordionTab header="More info">
          <div className='grid'>
            <div className='col-12'>
              <h3>{item.venuename}</h3>
            </div>
            {item.occurrences.length > 0 &&
              <DataTable value={item.occurrences} header="Dates" min className='col-12'>
                <Column field="date" />
                <Column field="starttime" />
              </DataTable>
            }
            <DataTable value={item.programme} header="Programme" className='col-12'>
              <Column field="composername" />
              <Column field="workname" />
            </DataTable>
            <DataTable value={item.performers} header="Performers" className='col-12'>
              <Column field="name" />
              <Column field="instrumentname" />
            </DataTable>
            
            {item?.buyticketsurl ?
            <div className='col-6'>
              <Button onClick={() => window.open(item?.buyticketsurl, "_blank")}>
                Buy
              </Button>
            </div>
          : null
          }
          <div className='col-6'>
            <Button onClick={() => openLocationInGoogleMaps(item.mapcoordinates)}>
              Open location
            </Button>
          </div>

          </div>
          
        </AccordionTab>
      </Accordion>
    )
  }

  return (
    <>
      <div className='grid head-search'>
        <div className='col-12'>
          <h1>Upcoming Mahler Concerts</h1>
        </div>
        
          <Toast ref={toastError} />
        
        <form className="p-inputgroup" onSubmit={handleSearch}>
          <InputText
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
          />
          {/* <Button label="Search" icon="pi pi-search" onClick={handleSearch} /> */}
          <Button label="Search" icon="pi pi-search" type='submit' />
        </form>
        <div className='col-6'>
          <small>Total results: {data.length}</small>
        </div>
        <div className='col-12'>
          <DataView value={data} itemTemplate={rowContent} />
        </div>
      </div>
    </>
  );
}

const openLocationInGoogleMaps = (coordinates) => {
  const googleMapsUrl = `https://maps.google.com?q=${coordinates.lat},${coordinates.lng}`;
  window.open(googleMapsUrl, "_blank");
};

export default App;
